// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

// Center figure captions
figure figcaption {
    text-align: center;
}

// Use 60% image width for desktop.
figure img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}

// Use full image width for mobile.
@media only screen and (max-width: 768px) {
    figure img {
        width: 100%;
    }
}

